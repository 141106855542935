<template>
<div class="tournament tournament-list" >
      <b-row class="d-flex align-items-center">
            <b-col cols="6" md="2" lg="1">
                  <b-img class="icon" :src="tournamentImage"></b-img>
            </b-col>
            <b-col cols="6" md="4" lg="5">
                  <h4>{{tournament.name}}</h4>
                  <p class="font-italic d-none d-sm-block">{{description}}</p>
            </b-col>
            <b-col cols="6" md="3" class="align-self-stretch mt-2 mt-md-0">
                  <div :class="classnames(active)" class="h-100 center py-2 py-md-0">
                        {{active.text}}
                  </div>
            </b-col>
            <b-col cols="6" md="3" class="align-self-stretch mt-2 mt-md-0" >                  
                 <div :class="classnames(userstatus)" class="h-100 center py-2 py-md-0"> {{userstatus.text}}</div>
            </b-col>
      </b-row>
</div>
</template>

<script>
import tournamentsenum from "@/helpers/enums/tournaments";

export default {
      name: 'Login',
      props: ["tournament", "useraccess"],
      components: {},
      data() {
            return {
                  user: {
                        username: "",
                        email: "",
                        password: ""
                  }
            };
      },
      computed: {
            status() {
                  return tournamentsenum.status[this.tournament.status];
            },
            active() {
                  return tournamentsenum.getTournamentActiveItem(this.tournament.active);
            },
            tournamentImage() {
                  return this.$images.tournamentImage(this.tournament.uuid, this.tournament.imgversion);
            },
            description() {
                  return this.tournament.description;
            },
            userstatus() {
                  return tournamentsenum.getUserAccessItem(this.useraccess, this.tournament.active);
            }
      },
      methods: {
            classnames(item)
            {
                  return "text-"+item.txt+" bg-"+item.bg;
            }
      }
};
</script>
